<template>
  <div class="admin-feed-block AdminStatisticsUsersFans">
    <AdminStatisticsFilter :statistics="statistics" :title="$locale['fans']">
      <LargeButton
        :icon="`users`"
        :label="$locale['total_fans']"
        :rightDesc="`<strong skeleton>${number_format(statistics.totalItems)}</strong>`"
        @click="setQuery({ admin: 'statistics-flowers-details', startDate, endDate, period: 'custom' })"
        prevent
      />
    </AdminStatisticsFilter>

    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          v-for="(creator, index) in creators"
          :key="index"
          :label="creator.name"
          :desc="date_locale(creator.date)"
          :card="{ desc: date_locale(creator.date), num: creator.signupCountry, numLabel: creator.signupCity }"
          :UserAvatar="creator"
          :iconSize="`48px`"
          :u="creator._id"
          @click="AdminStatisticsUserMenu(creator)"
          :rightDesc="`<strong skeleton>${number_format(creator.totalSubscriptions)}</strong><small skeleton>${$locale['subscriptions']}</small>`"
        />
        <LoadMore :morepage="statistics.hasMore" @more="getStatistics({ page: statistics.page + 1 })" />
      </div>
    </div>
    <Spacer num=".5" />
  </div>
</template>

<script>
import AdminStatistics from "./AdminStatistics";
export default {
  mixins: [AdminStatistics],
  components: {
    AdminStatisticsFilter: () => import("./AdminStatisticsFilter.vue"),
    AdminStatisticsUsersCreatorsCard: () => import("./AdminStatisticsUsersCreatorsCard.vue"),
    LastPeriodFilter: () => import("../widgets/LastPeriodFilter.vue"),
    TotalCard: () => import("../widgets/TotalCard.vue"),
  },
  data: function() {
    return {
      loading: true,
    };
  },
  methods: {
    openFilter: function() {
      this.$refs.context.open();
    },
    setPeriod: function(data) {
      const { period, startDate, endDate } = typeof data === "string" ? { period: data } : data;
      this.setQuery({ period, startDate, endDate });
    },
    getQuery: function({ page }) {
      const { period, startDate, endDate } = this.$route.query;
      let query = `?period=${period}&page=${page < 1 ? 1 : page}`;
      query += `&startDate=${startDate}&endDate=${endDate}`;
      return query;
    },
    getStatistics: async function({ page }) {
      this.isLoading(true);
      try {
        const query = this.getQuery({ page });
        const response = await this.$api.get(`${process.env.VUE_APP_API_HOST}admin/statistics-fans${query}`);
        this.$store.commit("setAdminFansStatistics", { ...response.data });
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
    getPercent: function(mount, total) {
      return Math.round((mount / total) * 100);
    },
    userDate: function(date) {
      return this.date_locale(date);
    },
  },
  mounted: function() {
    this.isLoading(true);
    setTimeout(() => {
      this.getStatistics({ page: 1 });
    }, 1000);
  },
  computed: {
    lang: function() {
      return this.$locale.statistics;
    },
    period: function() {
      return this.$route.query.period || "last-6-months";
    },
    periodLabel: function() {
      const fromDate = this.$global.dateLocaleFormat(this.statistics.startDate);
      const toDate = this.$global.dateLocaleFormat(this.statistics.endDate);
      return `${fromDate}, ${toDate}`;
    },
    creators: function() {
      return this.$store.getters.admin.fans.items;
    },
    statistics: function() {
      return this.$store.getters.admin.fans.statistics;
    },
  },
  watch: {
    "$route.query": function() {
      this.getStatistics({ page: 1 });
    },
  },
};
</script>
